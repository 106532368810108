function Message(){
    const message = document.createElement('p');
    message.innerHTML = `<b>*</b> HaxBall main logo is a link back to home settings of the page. <b>*</b>
    <br>
    <b>**</b> Tables are sortable via clicking the field header. On mobile, click on any row <b>**</b>
    `

    return message;
}

export default Message;